import './heartbeat.css';
export default function Heartbeat({wordText = "每次遇见你都心跳加速!"}) {
    return (
        <div id="body">
            <div id="frame">
                <div className="heart left"></div>
                <div className="heart right"></div>
                <div className="heart bottom"></div>
            </div>
            <b className="word">{wordText}</b>
        </div>
    );
}
