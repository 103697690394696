'use client';

import Heartbeat from '../_components/Heartbeat';
import Page002 from '../002/page'
import { useEffect, useState } from "react";

async function getConfig(path) {
    try {
        const response = await fetch(`/api/say_love/query_by_path?path=${path}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Data:', data);
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}

export default function Page({ params }) {
    const [data, setData] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        async function fetchData() {
            try {
                const path = params.user;
                const result = await getConfig(path);
                console.log("path:", path, "result:", result);
                setData(result);

                if (result) {
                    const { config } = result;

                    const { steps } = config;

                    const navigateNext = (index) => {
                        if (index < steps.length) {
                            const { delay } = steps[index];
                            setCurrentStep(index);
                            setTimeout(() => navigateNext(index + 1), delay);
                        }
                    };

                    navigateNext(0);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, [params]);

    if (!data) {
        return <div>Loading...</div>;
    }

    const { steps } = data.config;
    const currentPage = steps[currentStep]?.url || '/default';

    return (
        <div>
            {currentPage === '/001' && <Component001 />}
            {currentPage === '/002' && <Component002 />}
            {currentPage === '/004' && <Component004 />}
            {/* 继续添加其他页面组件 */}
            {/*<Heartbeat />*/}
        </div>
    );
}

function Component001() {
    return <Heartbeat></Heartbeat>;
}

function Component002() {
    return <Page002></Page002>;
}

function Component004() {
    return <div>Page 004</div>;
}
